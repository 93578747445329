import React from 'react';
import Dove from './dove';

const FlyingDoves = ({ count }) => {
  return (
    <div className='App' style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      {/* Render multiple Dove components */}
      {[...Array(count)].map((_, index) => (
        <Dove key={index} />
      ))}
    </div>
  );
};

export default FlyingDoves;
