import React from 'react';
import '../App.css';

function Cell({ value, onClick, onReveal, onFlag, isRevealed, isFlagged, isPopupOpen, row, col }) {
  const renderValue = () => {
    if (!isRevealed) {
      return isFlagged ? '🚩' : null;
    }
    if (value === 'X') {
      return '🩷';
    }
    return value || null;
  };

  // Determine the class for the checkered pattern
  const cellClass = (row + col) % 2 === 0 ? 'cell light' : 'cell dark';

  const handleCellClick = () => {
    if (!isRevealed) {
      onClick();
    }
  };

  return (
    <div className="cell-container" onClick={handleCellClick}>
      <button
        className={`${cellClass} ${isRevealed ? 'revealed' : ''}`}
      >
        {renderValue()}
      </button>

      {isPopupOpen && (
        <div className="popup">
          <button onClick={onReveal}>🔎</button>
          <button onClick={onFlag}>{isFlagged ? '🗑️' : '🚩'}</button>
        </div>
      )}
    </div>
  );
}

export default Cell;
