import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import '../App.css';
import { useNavigate } from 'react-router-dom';


// Component for moving shapes (hearts)
function MovingShape() {
  const initialX = Math.random() * window.innerWidth;
  const initialY = Math.random() * window.innerHeight;

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: async (next) => {
      while (1) {
        await next({ opacity: 0.8 });
        await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));
        await next({ opacity: 0 });
        await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));
      }
    },
    config: { duration: 3000 }
  });

  return (
    <animated.div className="shape" style={{
      transform: `translate3d(${initialX}px, ${initialY}px, 0)`,
      opacity: opacity,
    }} />
  );
}

// Component for moving words
function MovingWord() {
  const words = ["You're cute", "My love", "My gorgeous", "My beautiful", "You're so fine", "My sweetheart", "Cutie", "My heart", "Kiss me?"];
  const randomWord = words[Math.floor(Math.random() * words.length)];
  const initialX = Math.random() * window.innerWidth;
  const initialY = Math.random() * window.innerHeight;

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: async (next) => {
      while (1) {
        await next({ opacity: 1 });
        await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));
        await next({ opacity: 0 });
        await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));
      }
    },
    config: { duration: 2000 }
  });

  return (
    <animated.div className="word" style={{
      transform: `translate3d(${initialX}px, ${initialY}px, 0)`,
      opacity: opacity,
    }}>
      {randomWord}
    </animated.div>
  );
}

const Cool = () => {
  const countShapes = 60;
  const countWords = 30;
  const navigate = useNavigate();

  const shapes = Array.from({ length: countShapes }, (_, index) => <MovingShape key={index} />);
  const words = Array.from({ length: countWords }, (_, index) => <MovingWord key={index} />);
  const handleBackHomeClick = () => {
    // Call navigate function to navigate back home
    togglePlayPause()
    navigate('/home');
  };
  
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio('/beautifulthings.mp3'));

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying); // Toggle the state between true and false
  };
  useEffect(() => {
    // Call your function here
    togglePlayPause();
  }, []); // The empty array [] means this effect will only run once on mount


  return (
    <div class="backscreen">
        <a className="back-button" onClick={handleBackHomeClick}>Back Home</a>
        <a className="play-button" onClick={togglePlayPause}>
            {isPlaying ? 'Pause' : 'Play'}
        </a>
        <div className="background">
        {shapes}
        {words}
        </div>
    </div>
  );
};

export default Cool;
