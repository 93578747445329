import React, { useState, useEffect } from 'react';
import '../App.css'; // Import CSS file for styling

const Welcome = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Triggering the fade in effect after component mounts
    setTimeout(() => {
      setFadeIn(true);
    }, 100);
  }, []);

  return (
    <div className={`welcome ${fadeIn ? 'fade-in' : ''}`}>
      <div className="fantasy-font">Welcome</div>
    </div>
  );
};

export default Welcome;
