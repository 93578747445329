// DogsScreen.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Dogs = () => {
  const [dogImages, setDogImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch dog images from the Dog CEO API
    const fetchDogImages = async () => {
      try {
        const response = await axios.get('https://dog.ceo/api/breeds/image/random/20');
        setDogImages(response.data.message);
      } catch (error) {
        console.error('Error fetching dog images:', error);
      }
    };

    fetchDogImages();
  }, []);

  const handleBackHomeClick = () => {
    // Call navigate function to navigate back home
    navigate('/home');
  };

  return (
    <div >
      <a className="back-button" onClick={handleBackHomeClick}>Back Home</a>
      <div className="dog-images">
        {dogImages.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`Dog ${index}`} className="dog-image" />
        ))}
      </div>
    </div>
  );
};

export default Dogs;
