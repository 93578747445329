import React, { useState, useEffect } from 'react';
import Timer from '../components/timer';
import Welcome from '../components/welcome';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const WelcomeScreen = () => {
  const [timerDone, setTimerDone] = useState(false);
  const [welcomeDone, setWelcomeDone] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Set timerDone to true after 2 seconds
    const timer = setTimeout(() => {
      setTimerDone(true);
    }, 13000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (timerDone) {
      // Set welcomeDone to true after 3 seconds
      const welcomeTimer = setTimeout(() => {
        setWelcomeDone(true);
      }, 3000);

      return () => clearTimeout(welcomeTimer);
    }
  }, [timerDone]);

  const goHome = () => {
    // Redirect to a separate page when a heart is clicked
    navigate('/home');
  };

  return (
    <div className="App">
      <header className="App-header">
        <Timer />
        {!welcomeDone && timerDone && <Welcome onWelcomeDone={() => setWelcomeDone(true)} />}
        {welcomeDone && goHome()}
      </header>
    </div>
  );
};

export default WelcomeScreen;
