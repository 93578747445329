import React, { useState, useEffect } from 'react';
import Cell from './cell';

const createBoard = (rows, cols, mines) => {
    let board = Array(rows)
      .fill(null)
      .map(() => Array(cols).fill(null)
        .map(() => ({
          value: 0,
          isRevealed: false,
          isFlagged: false,
        }))
      );
  
    let minesPlaced = 0;
    while (minesPlaced < mines) {
      const row = Math.floor(Math.random() * rows);
      const col = Math.floor(Math.random() * cols);
      if (board[row][col].value !== 'X') {
        board[row][col].value = 'X';
        minesPlaced++;
      }
    }
  
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        if (board[row][col].value !== 'X') {
          board[row][col].value = calculateMines(board, row, col);
        }
      }
    }
  
    return board;
  };

const calculateMines = (board, row, col) => {
  const directions = [
    [-1, -1], [-1, 0], [-1, 1],
    [0, -1],          [0, 1],
    [1, -1], [1, 0], [1, 1],
  ];
  let mines = 0;
  directions.forEach(([dx, dy]) => {
    const newRow = row + dx;
    const newCol = col + dy;
    if (newRow >= 0 && newRow < board.length && newCol >= 0 && newCol < board[0].length) {
      if (board[newRow][newCol].value === 'X') {
        mines++;
      }
    }
  });
  return mines;
};

const revealEmptyCells = (board, row, col) => {
  const directions = [
    [-1, -1], [-1, 0], [-1, 1],
    [0, -1],          [0, 1],
    [1, -1], [1, 0], [1, 1],
  ];

  directions.forEach(([dx, dy]) => {
    const newRow = row + dx;
    const newCol = col + dy;
    if (newRow >= 0 && newRow < board.length && newCol >= 0 && newCol < board[0].length) {
      if (!board[newRow][newCol].isRevealed && !board[newRow][newCol].isFlagged && board[newRow][newCol].value !== 'X') {
        board[newRow][newCol].isRevealed = true;
        if (board[newRow][newCol].value === 0) {
          revealEmptyCells(board, newRow, newCol);
        }
      }
    }
  });
};

function Board({ rows = 10, cols = 10, mines = 10, onReset, onWin, onLoss, timer }) {
  const [board, setBoard] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [minesRemaining, setMinesRemaining] = useState(mines);
  const [openPopup, setOpenPopup] = useState(null); // Track which cell's popup is open

  useEffect(() => {
    setBoard(createBoard(rows, cols, mines));
  }, [rows, cols, mines]);

  const handleCellClick = (row, col) => {
    if (gameOver || board[row][col].isRevealed) return;

    // Toggle the popup for the clicked cell
    if (openPopup && openPopup.row === row && openPopup.col === col) {
      setOpenPopup(null); // Close the popup if it's already open for this cell
    } else {
      setOpenPopup({ row, col }); // Open the popup for this cell
    }
  };

  const handleReveal = (row, col) => {
    if (gameOver || board[row][col].isRevealed) return;

    const newBoard = board.slice();
    if (newBoard[row][col].value === 'X') {
      newBoard.forEach(row => row.forEach(cell => cell.isRevealed = true));
      setBoard(newBoard);
      setGameOver(true);
      onLoss();
    } else {
      newBoard[row][col].isRevealed = true;
      if (newBoard[row][col].value === 0) {
        revealEmptyCells(newBoard, row, col);
      }
      setBoard(newBoard);
      checkWin(newBoard);
    }

    setOpenPopup(null); // Close the popup after revealing
  };

  const handleFlag = (row, col) => {
    if (gameOver || board[row][col].isRevealed) return;

    const newBoard = board.slice();
    if (newBoard[row][col].isFlagged) {
      newBoard[row][col].isFlagged = false;
      setMinesRemaining(minesRemaining + 1);
    } else if (minesRemaining > 0) {
      newBoard[row][col].isFlagged = true;
      setMinesRemaining(minesRemaining - 1);
    }
    setBoard(newBoard);
    setOpenPopup(null); // Close the popup after flagging/unflagging
    checkWin(newBoard);
  };

  const checkWin = (board) => {
    // ... (existing code for checking win condition)
  };

  return (
    <div>
      <div className="header-container">
        <div className="mines-remaining">Mines Remaining: {minesRemaining}</div>
        <div className="timer">{timer}</div>
        <button className="reset-button" onClick={onReset}>Reset Game</button>
      </div>
      <div className="board">
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((cell, colIndex) => (
              <Cell
                key={`${rowIndex}-${colIndex}`}
                value={cell.value}
                isRevealed={cell.isRevealed}
                isFlagged={cell.isFlagged}
                onClick={() => handleCellClick(rowIndex, colIndex)}
                onReveal={() => handleReveal(rowIndex, colIndex)}
                onFlag={() => handleFlag(rowIndex, colIndex)}
                isPopupOpen={openPopup && openPopup.row === rowIndex && openPopup.col === colIndex}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Board;