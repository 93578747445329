import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import '../App.css';


const WinCounter = () => {
  const [wins, setWins] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Configure the AWS SDK
  AWS.config.update({
    region: 'us-east-2', // Replace with your region
    // Optionally configure credentials here, or ensure they are set in your environment
  });

  const dynamoDB = new AWS.DynamoDB.DocumentClient();
  const tableName = 'wincounter';
  const itemId = 'dove';

  useEffect(() => {
    const fetchWins = async () => {
      try {
        const params = {
          TableName: tableName,
          Key: { id: itemId },
        };

        const result = await dynamoDB.get(params).promise();

        if (result.Item && result.Item.wins !== undefined) {
          setWins(result.Item.wins);
        } else {
          setWins(0); // Default to 0 if 'wins' doesn't exist
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching win count:', err);
        setError('Failed to load win count.');
        setLoading(false);
      }
    };

    fetchWins();
  }, []); // Empty dependency array ensures this runs once after the component mounts

  if (loading) return <div>Loading wins...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='wins'>
      Current Wins: {wins}
    </div>
  );
};

export default WinCounter;
