// HomeScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeartIcon from '../components/heart_icon';

const HomeScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="home-screen">
      <p style={{fontSize: '25px'}}>Pick a heart.</p>
      
      <div className="heart-container">
        <HeartIcon onClick={()=>navigate('/dogs')} />
        <HeartIcon onClick={()=>navigate('/fun')} />
        <HeartIcon onClick={()=>navigate('/hp')} />
        {/* Add more hearts as needed */}
      </div>
    </div>
  );
};

export default HomeScreen;
