// DogsScreen.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Confetti from 'react-confetti';
import Board from "../components/board";
import Modal from "../components/model";
import WinCounter from '../components/wincounter';


const Hp = () => {
  const [key, setKey] = useState(0);
  const [isWinner, setIsWinner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLossModal, setShowLossModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [mines, setMines] = useState(generateRandomMines());

  useEffect(() => {
    let timer;
    if (!isWinner) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isWinner]);

  const resetGame = () => {
    setKey(prevKey => prevKey + 1);
    setIsWinner(false); // Reset the winner state
    setShowModal(false);
    setShowLossModal(false);
    setSeconds(0); // Reset the timer
  };

  const handleWin = () => {
    setIsWinner(true); // Trigger the confetti
    playWinSound(); // Play the win sound
    setShowModal(true); // Show the modal when the player wins
  };

  const handleLoss = () => {
    playLossSound(); // Play the win sound
    setShowLossModal(true); // Show the modal when the player wins
  };

  const navigate = useNavigate();

  const handleBackHomeClick = () => {
    // Call navigate function to navigate back home
    navigate("/home");
  };

  const playWinSound = () => {
    const audio = document.getElementById('win-sound');
    if (audio) {
      audio.play();
    }
  };

  const playLossSound = () => {
    const audio = document.getElementById('loss-sound');
    if (audio) {
      audio.play();
    }
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  function generateRandomMines() {
    const minMines = 40;
    const maxMines = 50;
    return Math.floor(Math.random() * (maxMines - minMines + 1)) + minMines;
  }

  return (
    <div className="app-container">
      {isWinner && <Confetti   numberOfPieces={500} gravity={0.03}/>} {/* Show confetti when the player wins */}
        <a
          className="back-button"
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            backgroundColor: "brown",
          }}
          onClick={handleBackHomeClick}
        >
          Back Home
        </a>

       
          <br></br>
          <br></br>
          <div className="game-container">
          <Board key={key} rows={14} cols={14} mines={mines} onReset={resetGame} onWin={handleWin} onLoss = {handleLoss} timer = {formatTime(seconds)} />
          </div>
          <div className="wins"><WinCounter/></div>
          

      <audio id="win-sound" src="/win.wav" preload="auto"></audio> {/* Audio element for win sound */}
      <Modal show={showModal} onClose={resetGame}>
        <div>Eyoooo! You won! 🎉</div>
      </Modal>
      <audio id="loss-sound" src="/loss.wav" preload="auto"></audio> {/* Audio element for win sound */}
      <Modal show={showLossModal} onClose={resetGame}>
        <div>Oowf... You Lose! 💀 </div>
      </Modal>
    </div>
  );
};

export default Hp;
