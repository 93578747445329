import React, { useState, useEffect } from 'react';
import FlyingDoves from './flying_doves.js';

const Timer = () => {
  const [showDoves, setShowDoves] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the doves after 15 seconds
    const timer = setTimeout(() => {
      setShowDoves(false);
    }, 13000);
    
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showDoves && <FlyingDoves count={25} />}
    </div>
  );
};

export default Timer;
