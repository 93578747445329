import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import '../App.css';

const Dove = ({}) => {
  const [flapIndex, setFlapIndex] = useState(0); // State to track the current flap index
  const [showMessage, setShowMessage] = useState(false); // State to manage the visibility of the message
  const [dovePosition, setDovePosition] = useState({ left: Math.random() * 80, top: Math.random() * 80 }); // State to track dove position

  // Define animation properties for flapping wings using useSpring hook
  const wingProps = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: async (next) => {
      while (true) {
        // Wing up
        await next({ transform: 'rotate(-5deg)' });
        // Wing down
        await next({ transform: 'rotate(5deg)' });
      }
    },
    config: { duration: 300 }, // Adjust duration for flapping speed
  });

  // Define animation properties for moving using useSpring hook
  const moveProps = useSpring({
    from: { left: `${dovePosition.left}%`, top: `${dovePosition.top}%` },
    to: async (next) => {
      while (true) {
        const randomLeft = Math.random() * 80; // Random left position within 80% of viewport width
        const randomTop = Math.random() * 80; // Random top position within 80% of viewport height

        await next({ left: `${randomLeft}%`, top: `${randomTop}%` });
        await next({ left: `${randomLeft}%`, top: `${randomTop}%` });
        setDovePosition({ left: randomLeft, top: randomTop });
      }
    },
    config: { duration: 15000 }, // Adjust duration for slower movement
  });

  // Function to cycle through flap images
  useEffect(() => {
    const interval = setInterval(() => {
      setFlapIndex((prevIndex) => (prevIndex + 1) % 2); // Cycling through 0, 1
    }, 300); // Sync with flapping animation duration

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to toggle message visibility
  const toggleMessage = () => {
    setShowMessage(!showMessage);
  };

  return (
    <animated.div
      className='App'
      style={{
        position: 'absolute',
        width: '50px',
        height: 'auto',
        ...wingProps,
        ...moveProps,
      }}
      onClick={toggleMessage}
    >
      <img
        src={`dove_${flapIndex + 1}.png`}
        alt="Dove"
        style={{ width: '100%', height: '100%' }}
      />
      {showMessage && (
        <div
          className="message-bubble"
          style={{
            position: 'absolute',
            padding: '10px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <p><span role="img" aria-label="heart">❤️</span> Hey cutie</p>
        </div>
      )}
    </animated.div>
  );
};

export default Dove;
