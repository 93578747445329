
// Filename - App.js
 
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import DogPics from "./screens/dogs";
import Welcome from "./screens/welcomeScreen";
import Home from "./screens/home";
import CoolScreen from "./screens/cool";
import HP from "./screens/hp";


function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={<Welcome />}
                    />
                    <Route
                        exact
                        path="/dogs"
                        element={<DogPics />}
                    />
                    <Route
                        exact
                        path="/home"
                        element={<Home />}
                    />
                    <Route
                        exact
                        path="/fun"
                        element={<CoolScreen />}
                    /> 
                    <Route
                        exact
                        path="/hp"
                        element={<HP />}
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
}
 
export default App;